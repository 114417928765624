import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AbstractModal } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-troubleshooting-iframe',
  templateUrl: './troubleshooting-iframe.component.html',
  styleUrls: ['./troubleshooting-iframe.component.scss'],
})
export class TroubleshootingIframeComponent extends AbstractModal<{ troubleshootingUrl: string }> {
  private safeTroubleshootingUrl!: SafeResourceUrl;
  loading = true;

  get troubleshootingUrl(): SafeResourceUrl {
    if (this.safeTroubleshootingUrl) {
      return this.safeTroubleshootingUrl;
    }
    const url = this.data?.troubleshootingUrl ?? '';
    this.safeTroubleshootingUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    return this.safeTroubleshootingUrl;
  }

  constructor(private sanitizer: DomSanitizer) {
    super();
  }
}

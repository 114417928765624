<div class="pre-surgery-feedback" data-cy="feedback" *ngIf="state">
  <div
    class="c-notification"
    [ngClass]="{
      'c-notification__info': state.indexOf('Info') > -1,
      'c-notification__warning': state.indexOf('Warning') > -1,
      'c-notification__error': state.indexOf('Error') > -1
    }"
    [ngSwitch]="state">
    <ng-container *ngSwitchCase="SoundCheckStates.AUDIO_DEFAULT_INFO">
      <h3 class="c-notification__title">Speakers</h3>
      <p class="u-mb0@xs">You may need to adjust the volume on your&nbsp;device.</p>
    </ng-container>

    <ng-container *ngSwitchCase="SoundCheckStates.AUDIO_ADVICE_ERROR">
      <h3 class="c-notification__title">Having audio issues?</h3>
      <p class="u-mb0@xs">
        If you are unsure of how to fix it, take a look at our&nbsp;<a
          class="cursor-pointer"
          [pushdrTroubleshooting]="TroubleshootingPage.Sound"
          trackClick="help"
          >advice</a
        >.
      </p>
    </ng-container>
  </div>
</div>

<div class="animation-background">
  <div class="sound-animation-container animate u-flex">
    <div class="level u-flex u-flex-column">
      <div class="level-gray-1"></div>
      <div class="level-blue-1"></div>
    </div>
    <div class="level u-flex u-flex-column">
      <div class="level-gray-2"></div>
      <div class="level-blue-2"></div>
    </div>
    <div class="level u-flex u-flex-column">
      <div class="level-gray-3"></div>
      <div class="level-blue-3"></div>
    </div>
    <div class="level u-flex u-flex-column">
      <div class="level-gray-4"></div>
      <div class="level-blue-4"></div>
    </div>
    <div class="level u-flex u-flex-column">
      <div class="level-gray-5"></div>
      <div class="level-blue-5"></div>
    </div>
  </div>
</div>

<audio
  style="display: none; opacity: 0"
  #audioEl
  autoplay
  title="local audio file"
  controls=""
  loop>
  <source [src]="soundURL" type="audio/mp3" crossorigin="anonymous" />
</audio>
<p>Can you clearly hear the test music?</p>
<div class="pre-surgery-progression">
  <div class="pre-surgery-buttons">
    <button
      class="c-btn c-btn--primary c-btn--small lg:mb-0"
      data-cy="next"
      (click)="next()"
      data-cy="next"
      trackClick="yes">
      Yes
    </button>
    <button
      [pushdrTroubleshooting]="TroubleshootingPage.Sound"
      data-cy="no"
      class="c-btn c-btn--primary c-btn--white-bg pre-surgery--btn_margin c-btn--small lg:mb-0"
      (click)="userSaidNo()">
      No
    </button>
  </div>
</div>

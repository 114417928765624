<div class="container disrupted" trackView="disrupted" style="padding: 0 16px">
  <ng-container>
    <div class="c-notification">
      <h2 class="c-notification__title u-font-style-h4">Sorry!</h2>
      <p class="u-mb0@xs">Your consultation ended unsuccessfully</p>
    </div>
    <div class="u-mb48@xs">
      <h3>Reconnect to your Clinician</h3>
      <p>
        If the interuption was due to connection, hardware or identification reasons, you can
        reconnect to your Clinician.
      </p>
      <button (click)="reconnect()" class="c-btn c-btn--primary u-mb0@xs" trackClick="reconnect">
        Reconnect
      </button>
    </div>
    <div class="c-divider c-divider--or u-mb48@xs u-mt0@xs"></div>
    <div class="u-mt0@xs">
      <h3>End your session</h3>
      <p>If the Clinician was unable to continue with the consultation, your session has ended.</p>
      <button
        (click)="exit()"
        class="c-btn c-btn--secondary c-btn--white-bg"
        trackClick="end session">
        End session
      </button>
    </div>
  </ng-container>
</div>

import { Injectable } from '@angular/core';
import { ModalService } from '@pushdr/common/overlay';
import { SystemCheckService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { TroubleshootingIframeComponent } from './troubleshooting-iframe.component';

export enum TroubleshootingPage {
  Camera = 'cameraIssuesUrl',
  Microphone = 'microphoneIssuesUrl',
  Sound = 'soundIssuesUrl',
  Connection = 'connectionIssuesUrl',
}

@Injectable({
  providedIn: 'root',
})
export class TroubleshootingService {
  constructor(
    private envProxy: EnvironmentProxyService,
    private systemCheck: SystemCheckService,
    private modal: ModalService
  ) {}

  showTroubleshootingPage(page: TroubleshootingPage) {
    const urls = this.getTroubleshootingUrls();
    const pageUrl = urls[page];
    this.showTroubleshootingModal(pageUrl);
  }

  private showTroubleshootingModal(troubleshootingUrl: string) {
    this.modal.showCustom(TroubleshootingIframeComponent, {
      troubleshootingUrl: troubleshootingUrl,
    });
  }

  private getTroubleshootingUrls() {
    const key = this.systemCheck.isWindows ? 'windows' : this.systemCheck.isMac ? 'mac' : 'generic';
    return this.envProxy.environment.patient.troubleshooting[key];
  }
}

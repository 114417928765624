import { Component, OnInit } from '@angular/core';
import { GetPostConsultationThankyouMessages } from '@pushdr/common/types';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { EnvironmentProxyService } from '@pushdr/environment';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { FeedbackReminderService } from '@pushdr/feedback/reminder';

@Component({
  selector: 'pushdr-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent implements OnInit {
  thankYouMessages: GetPostConsultationThankyouMessages;

  orderId$ = this.route.queryParams.pipe(map(params => params.orderId));

  feedbackUrl$ = this.orderId$.pipe(
    map(orderId => `${this.envProxy.environment.patient.account.url}/feedback/${orderId}`)
  );

  constructor(
    private api: ApiPatientService,
    private envProxy: EnvironmentProxyService,
    private route: ActivatedRoute,
    private reminder: FeedbackReminderService
  ) {}

  ngOnInit() {
    this.getThankYouMessages();
  }

  getThankYouMessages() {
    this.api.consultation.getPostConsultationThankYouMessages().subscribe(
      response => {
        this.thankYouMessages = response;
      },
      error => {
        this.continue();
      }
    );
  }

  continue() {
    this.reminder.postpone();
    location.href = this.envProxy.environment.patient.account.url;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AnalyticsService, PushDoctorLogsService } from '@pushdr/common/data-access/analytics';
import {
  BrowserDetectorService,
  ExtendedWindow,
  SystemCheckService,
  WINDOW,
} from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { AccountProfileService } from '@pushdr/patientapp/common/utils';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'pushdr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isIE10 = false;

  constructor(
    private systemCheck: SystemCheckService,
    private analytics: AnalyticsService,
    private router: Router,
    private profile: AccountProfileService,
    private envProxy: EnvironmentProxyService,
    private browserDetectorService: BrowserDetectorService,
    private logger: PushDoctorLogsService,
    @Inject(WINDOW) private window: ExtendedWindow
  ) {}

  ngOnInit() {
    this.checkForMaintenanceFeatureFlag();
    this.logger.alwaysStore = true;
    this.isIE10 = this.browserDetectorService.isIE10();
    this.analytics.initialise('patientapp-surgery');
    this.setUser();
    this.systemCheck.initialise();
    this.guardAgainstNotSupported();
  }

  setUser() {
    this.profile.externalId$.subscribe((id: string) => {
      if (id) {
        this.analytics.setUser({ id });
      }
    });
  }

  checkForMaintenanceFeatureFlag() {
    // Replaces 'maintenance' optimizely flag
    // TODO: Add proper maintenance flag toggling from API
    const maintenanceEnabled = false;
    if (maintenanceEnabled) {
      this.window.location.href = this.envProxy.environment.patient.funnel.maintenance;
    }
  }

  guardAgainstNotSupported() {
    this.routeToNotSupported();
    this.router.events
      .pipe(
        filter(event => {
          return event instanceof NavigationStart;
        })
      )
      .subscribe(url => {
        this.routeToNotSupported();
      });
  }

  routeToNotSupported() {
    if (!this.systemCheck.isCompatible) {
      this.router.navigate(['not-supported']);
    }
  }
}

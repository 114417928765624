<div class="pre-surgery-feedback" data-cy="feedback" *ngIf="state">
  <div
    class="c-notification"
    [ngClass]="{
      'c-notification__info': state.indexOf('Info') > -1,
      'c-notification__warning': state.indexOf('Warning') > -1,
      'c-notification__error': state.indexOf('Error') > -1
    }"
    [ngSwitch]="state">
    <ng-container *ngSwitchCase="MicCheckStates.MICROPHONE_DEFAULT_INFO">
      <h3 class="c-notification__title">Microphone</h3>
      <p class="u-mb0@xs">Start speaking to make sure they can hear&nbsp;you</p>
    </ng-container>

    <ng-container *ngSwitchCase="MicCheckStates.MICROPHONE_ADVICE_ERROR">
      <h3 class="c-notification__title">Having microphone issues?</h3>
      <p class="u-mb0@xs">
        If you are unsure of how to fix it, take a look at our&nbsp;<a
          class="cursor-pointer"
          [pushdrTroubleshooting]="TroubleshootingPage.Microphone"
          trackClick="help"
          >advice</a
        >.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="MicCheckStates.MICROPHONE_BLOCKED_ERROR">
      <h4 class="c-notification__title">Your microphone is blocked</h4>
      <p class="u-mb0@xs">
        You'll need to allow access to your microphone to continue. If you are unsure how to do this
        follow this&nbsp;<a
          class="cursor-pointer"
          [pushdrTroubleshooting]="TroubleshootingPage.Microphone"
          trackClick="help"
          >advice</a
        >.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="MicCheckStates.MICROPHONE_ISSUE_ERROR">
      <h4 class="c-notification__title">We are having trouble with your microphone</h4>
      <p class="u-mb0@xs">
        Sorry, your browser does not support our audio requirements. Please, consider upgrading to
        the latest version or downloading
        <a (click)="openBrowserDownload('chrome')">Google Chrome</a> or
        <a (click)="openBrowserDownload('firefox')">Mozilla Firefox</a>.
      </p>
    </ng-container>
  </div>
</div>

<div class="test-container u-m-auto">
  <div class="animation-container">
    <video id="localMicStream" style="display: none"></video>
    <canvas class="visualizer" id="micTest"></canvas>
  </div>
</div>
<div class="pre-surgery-progression"></div>
<p>Does the line move when you speak?</p>
<div class="pre-surgery-progression">
  <div class="pre-surgery-buttons">
    <button
      [disabled]="canProgress ? null : true"
      class="c-btn c-btn--primary c-btn--small lg:mb-0"
      data-cy="next"
      (click)="next()"
      data-cy="next"
      trackClick="yes">
      Yes
    </button>
    <button
      [disabled]="canProgress ? null : true"
      data-cy="no"
      [pushdrTroubleshooting]="TroubleshootingPage.Microphone"
      class="c-btn c-btn--primary c-btn--white-bg pre-surgery--btn_margin c-btn--small lg:mb-0"
      (click)="userSaidNo()">
      No
    </button>
  </div>
</div>

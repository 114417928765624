<div class="c-modal c-modal--is-visible">
  <div class="c-modal__wrap c-modal__wrap--40 modal">
    <button type="button" class="absolute top-0 right-0 p-2" (click)="close()">
      <i class="text-3xl material-icons">cancel</i>
    </button>
    <div class="modal__body h-full relative">
      <iframe
        #iframe
        class="w-full h-full"
        (load)="loading = !iframe.src"
        [src]="troubleshootingUrl"></iframe>
      <div *ngIf="loading" class="absolute -left-4 -top-4 -right-4 -bottom-4 bg-white">
        <pushdr-spinner-loader></pushdr-spinner-loader>
      </div>
    </div>
  </div>
</div>

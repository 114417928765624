import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SystemSupportComponent } from '@pushdr/patientapp/common/components';

const routes: Routes = [
  {
    path: 'not-supported',
    component: SystemSupportComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SystemNotSupportedRoutingModule {}

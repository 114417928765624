import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PostSurgeryRoutingControllerService } from './services/postsurgery-routing-controller/postsurgery-routing-controller.service';
import { ModalService } from '@pushdr/common/overlay';

@Component({
  selector: 'pushdr-postsurgery',
  templateUrl: './postsurgery.component.html',
  styleUrls: ['./postsurgery.component.scss'],
})
export class PostsurgeryComponent implements OnInit {
  constructor(
    private routingController: PostSurgeryRoutingControllerService,
    private router: Router,
    private modal: ModalService
  ) {}

  ngOnInit() {
    this.modal.showLoader({ bottomText: 'Loading' });
    this.redirectToCorrectPage();
  }

  private redirectToCorrectPage() {
    this.routingController.getRoutes().subscribe({
      next: () => {
        const nextRoute = this.routingController.getNextRoute();
        this.router.navigate(['/postsurgery/' + nextRoute.routeName], {
          replaceUrl: true,
          state: nextRoute.responseData,
          queryParamsHandling: 'preserve',
        });
      },
      error: () => {
        this.router.navigate(['/postsurgery/thankyou'], {
          replaceUrl: true,
          queryParamsHandling: 'preserve',
        }); // gracefully die...
      },
      complete: () => this.modal.close(),
    });
  }
}

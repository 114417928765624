import { Injectable, Injector } from '@angular/core';
import { TokenService } from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import {
  AccountProfileService,
  resolveTheCustomersAccountProfile,
} from '@pushdr/patientapp/common/utils';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private envProxy = this.inj.get(EnvironmentProxyService);
  private accountProfile = this.inj.get(AccountProfileService);
  private token = this.inj.get(TokenService);

  constructor(private inj: Injector) {}

  getAppConfig() {
    return Promise.all([
      this.redirectToSigninIfUserLacksAuth(this.token, this.envProxy),
      resolveTheCustomersAccountProfile(this.accountProfile),
    ]);
  }

  redirectToSigninIfUserLacksAuth(token: TokenService, envProxy: EnvironmentProxyService) {
    if (!token.exists()) {
      window.location.href = envProxy.environment.patient.funnel.signin;
    }
    return Promise.resolve();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TroubleshootingIframeComponent } from './troubleshooting-iframe.component';
import { TroubleshootingDirective } from './troubleshooting.directive';
import { SpinnerLoaderModule } from '@pushdr/common/components';

@NgModule({
  imports: [CommonModule, SpinnerLoaderModule],
  declarations: [TroubleshootingIframeComponent, TroubleshootingDirective],
  exports: [TroubleshootingDirective],
})
export class TroubleshootingModule {}

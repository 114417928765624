import { Component, OnInit } from '@angular/core';
import { PresurgeryService } from '@pushdr/patientapp/pre-surgery';
import { Router } from '@angular/router';

@Component({
  selector: 'pushdr-presurgery-wrapper',
  templateUrl: './pre-surgery-wrapper.component.html',
  styleUrls: ['./pre-surgery-wrapper.component.scss'],
})
export class PresurgeryWrapperComponent implements OnInit {
  constructor(private service: PresurgeryService, private router: Router) {}

  ngOnInit() {
    this.service.whenCompleted$().subscribe(() => {
      this.router.navigate(['/surgery/waitingroom']);
    });
  }
}

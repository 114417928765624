import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SpinnerLoaderModule } from '@pushdr/common/components';
import { PatientappCommonComponentsModule } from '@pushdr/patientapp/common/components';
import { AbusedConsultationComponent } from './abused-consultation/abused-consultation.component';
import { DisruptedConsultationComponent } from './disrupted-consultation/disrupted-consultation.component';
import { PreventBackButtonGuard } from './disrupted-consultation/guards/prevent-back-button.guard';
import { PostsurgeryRoutingModule } from './postsurgery-routing.module';
import { PostsurgeryComponent } from './postsurgery.component';
import { PostSurgeryRoutingControllerService } from './services/postsurgery-routing-controller/postsurgery-routing-controller.service';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { AnalyticsDirectivesModule } from '@pushdr/common/data-access/analytics';
import { AccountProfileModule } from '@pushdr/patientapp/common/utils';

@NgModule({
  imports: [
    CommonModule,
    PatientappCommonComponentsModule,
    PostsurgeryRoutingModule,
    AnalyticsDirectivesModule,
    AccountProfileModule,
    SpinnerLoaderModule,
    FormsModule,
  ],
  declarations: [
    PostsurgeryComponent,
    DisruptedConsultationComponent,
    ThankYouComponent,
    AbusedConsultationComponent,
  ],
  providers: [PostSurgeryRoutingControllerService, PreventBackButtonGuard],
})
export class PostsurgeryModule {}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TroubleshootingPage } from '@pushdr/troubleshooting';
import { PresurgeryService } from '../../services/patientapp-pre-surgery.service';

export enum SoundCheckStates {
  AUDIO_DEFAULT_INFO = 'AudioDefaultInfo',
  AUDIO_ADVICE_ERROR = 'AudioAdviceError',
}
@Component({
  selector: 'pushdr-sound-check',
  templateUrl: './sound-check.component.html',
  styleUrls: ['./sound-check.component.scss'],
})
export class SoundCheckComponent implements OnInit, OnDestroy {
  soundURL = `/assets/sounds/test_music.mp3`;
  @ViewChild('audioEl', { static: true }) audioPlayerRef: ElementRef;

  _state: SoundCheckStates = SoundCheckStates.AUDIO_DEFAULT_INFO;
  SoundCheckStates: typeof SoundCheckStates = SoundCheckStates;
  readonly TroubleshootingPage = TroubleshootingPage;

  constructor(private router: Router, private preSurgeryService: PresurgeryService) {}

  ngOnInit() {
    this.setSoundCheckPreSurgeryStage();
  }

  ngOnDestroy() {
    this.stopAudio(this.audioPlayerRef.nativeElement);
  }

  get state() {
    return this._state;
  }

  set state(newState) {
    this.preSurgeryService.stateName = this._state = newState;
  }

  next() {
    this.router.navigate([`${this.preSurgeryService.baseHref}/speed`]);
  }

  userSaidNo() {
    this.state = SoundCheckStates.AUDIO_ADVICE_ERROR;
  }

  private setSoundCheckPreSurgeryStage() {
    this.preSurgeryService.stage = 4;
    this.preSurgeryService.stateName = SoundCheckStates.AUDIO_DEFAULT_INFO;
  }

  private stopAudio(audioObj) {
    if (audioObj) {
      if (audioObj.pause) {
        audioObj.pause();
        audioObj.currentTime = 0;
      }
      if (audioObj.stop) {
        audioObj.stop();
      }
    }
  }
}

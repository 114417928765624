import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ExtendedWindow, WINDOW, WebrtcDevicesetupService } from '@pushdr/common/utils';
import { Observable, timer, Subject } from 'rxjs';
import { delayWhen, map, takeUntil } from 'rxjs/operators';
import { PresurgeryService } from '../../services/patientapp-pre-surgery.service';
import { DOCUMENT } from '@angular/common';
import { TroubleshootingPage } from '@pushdr/troubleshooting';

export enum MicCheckStates {
  MICROPHONE_DEFAULT_INFO = 'MicrophoneDefaultInfo',
  MICROPHONE_ISSUE_ERROR = 'MicrophoneIssueError',
  MICROPHONE_BLOCKED_ERROR = 'MicrophoneBlockedError',
  MICROPHONE_ADVICE_ERROR = 'MicrophoneAdviceError',
}
@Component({
  selector: 'pushdr-mic-check',
  templateUrl: './mic-check.component.html',
  styleUrls: ['./mic-check.component.scss'],
})
export class MicCheckComponent implements OnInit, OnDestroy {
  _state: MicCheckStates = MicCheckStates.MICROPHONE_DEFAULT_INFO;
  MicCheckStates: typeof MicCheckStates = MicCheckStates;
  canProgress = false;
  destroy$ = new Subject<boolean>();
  readonly TroubleshootingPage = TroubleshootingPage;

  constructor(
    private webRtcDevices: WebrtcDevicesetupService,
    private router: Router,
    private preSurgeryService: PresurgeryService,
    @Inject(WINDOW) private window: ExtendedWindow,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.setMicrophonePreSurgeryStage();
    this.isAudioEnabled().subscribe(
      audioEnabled => {
        if (audioEnabled) {
          this.unblocked();
        } else {
          this.blocked();
        }
      },
      error => {
        timer(2000).subscribe(() => this.blocked());
      }
    );
  }

  ngOnDestroy() {
    this.webRtcDevices.detachAllStreams();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get state() {
    return this._state;
  }

  set state(newState) {
    this.preSurgeryService.stateName = this._state = newState;
  }

  next() {
    this.canProgress = false;
    this.router.navigate([`${this.preSurgeryService.baseHref}/sound`]);
  }

  userSaidNo() {
    this.state = MicCheckStates.MICROPHONE_ADVICE_ERROR;
  }

  private isAudioEnabled(): Observable<boolean> {
    return this.webRtcDevices.getUserMediaStream('localMicStream', false, true).pipe(
      takeUntil(this.destroy$),
      delayWhen(localMedia => this.delayWhenBlocked(localMedia)),
      map((localMedia: any) => localMedia.permissions.audioEnabled)
    );
  }

  private delayWhenBlocked(localMedia: any) {
    let delayMiliseconds = 0;
    if (!localMedia.permissions.audioEnabled) {
      delayMiliseconds = 2000;
    }
    return timer(delayMiliseconds);
  }

  private setMicrophonePreSurgeryStage() {
    this.preSurgeryService.stage = 3;
    this.state = MicCheckStates.MICROPHONE_DEFAULT_INFO;
  }

  private blocked() {
    this.state = MicCheckStates.MICROPHONE_BLOCKED_ERROR;
  }

  private unblocked() {
    this.state = MicCheckStates.MICROPHONE_DEFAULT_INFO;
    this.canProgress = true;
    this.startMicrophoneVisualisation();
  }

  openBrowserDownload(browser: string) {
    let url = '';
    if (browser === 'chrome') {
      url = 'https://www.google.com/chrome/b/';
    } else if (browser === 'firefox') {
      url = 'https://www.mozilla.org/en-GB/firefox/new/';
    }
    this.document.open(url, '_blank');
  }

  private startMicrophoneVisualisation() {
    const localAudioStream = this.webRtcDevices.localMediaStream;
    const AudioContextProto = this.window.AudioContext || this.window.webkitAudioContext;
    if (!AudioContextProto) {
      this.state = MicCheckStates.MICROPHONE_ISSUE_ERROR;
      return;
    }
    const context = new AudioContextProto();
    //context.resume();
    const analyser = context.createAnalyser();
    // Create the microphone source
    const microphone = context.createMediaStreamSource(localAudioStream);
    // Connect the mic to the analyser
    microphone.connect(analyser);
    //analyser.connect(context.destination); //Uncomment this to output sound
    analyser.fftSize = 512; //Keep this low to reduce number of times it refreshes the canvas
    const bufferLength = analyser.frequencyBinCount; //an unsigned long value half that of the FFT size. This generally equates to the number of data values you will have to play with for the visualization
    const dataArray = new Uint8Array(bufferLength);
    analyser.getByteTimeDomainData(dataArray);
    // draw an oscilloscope of the current audio source
    const WIDTH = 300;
    const HEIGHT = 60;
    const canvas = <HTMLCanvasElement>document.getElementById('micTest');
    let canvasCtx = canvas.getContext('2d');
    canvas.width = WIDTH;
    canvas.height = HEIGHT;

    let draw = function () {
      if (draw) {
        requestAnimationFrame(draw);
        analyser.getByteTimeDomainData(dataArray);
        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
        canvasCtx.lineWidth = 3;
        canvasCtx.strokeStyle = 'rgb(7, 46, 253)';
        canvasCtx.beginPath();
        const sliceWidth = WIDTH / bufferLength;
        let x = 0;
        for (let i = 0; i < bufferLength; i++) {
          const v = dataArray[i] / 128.0;
          const y = (v * HEIGHT) / 2;

          if (i === 0) {
            canvasCtx.moveTo(x, y);
          } else {
            canvasCtx.lineTo(x, y);
          }
          x += sliceWidth;
        }
        canvasCtx.lineTo(canvas.width, canvas.height / 2);
        canvasCtx.stroke();
        if (document.getElementById('micTest') == null) {
          // to stop animation from being called
          draw = null;
          canvasCtx = null;
        }
      }
    };
    draw();
  }
}

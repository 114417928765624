import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { of, Observable, Subject } from 'rxjs';
import { PresurgeryService } from '../../services/patientapp-pre-surgery.service';
import { WebrtcDevicesetupService } from '@pushdr/common/utils';
import { TroubleshootingPage } from '@pushdr/troubleshooting';

export enum CameraCheckStates {
  CAMERA_DEFAULT_INFO = 'CameraDefaultInfo',
  CAMERA_ADVICE_ERROR = 'CameraAdviceError',
  CAMERA_BLOCKED_ERROR = 'CameraBlockedError',
}

@Component({
  selector: 'pushdr-video-check',
  templateUrl: './camera-check.component.html',
  styleUrls: ['./camera-check.component.scss'],
})
export class CameraCheckComponent implements OnInit, OnDestroy {
  _state: CameraCheckStates = CameraCheckStates.CAMERA_DEFAULT_INFO;
  CameraCheckStates: typeof CameraCheckStates = CameraCheckStates;
  canProgress = false;
  destroy$ = new Subject<boolean>();
  readonly TroubleshootingPage = TroubleshootingPage;

  constructor(
    private webRtcDevices: WebrtcDevicesetupService,
    private router: Router,
    private preSurgeryService: PresurgeryService
  ) {}

  ngOnInit() {
    this.setCameraPreSurgeryStage();
    this.isVideoEnabled().subscribe(videoEnabled => {
      if (videoEnabled) {
        this.cameraEnabled();
      } else {
        this.cameraDisabled();
      }
    });
  }

  ngOnDestroy() {
    this.webRtcDevices.detachAllStreams();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  get state() {
    return this._state;
  }

  set state(newState) {
    this.preSurgeryService.stateName = this._state = newState;
  }

  next() {
    this.canProgress = false;
    this.router.navigate([`${this.preSurgeryService.baseHref}/microphone`]);
  }

  userSaidNo() {
    this.state = CameraCheckStates.CAMERA_ADVICE_ERROR;
  }

  private isVideoEnabled(): Observable<boolean> {
    return this.webRtcDevices.getUserMediaStream('localVideoStream', true, false).pipe(
      takeUntil(this.destroy$),
      catchError(error => {
        return of({
          permissions: {
            videoEnabled: false,
          },
        });
      }),
      map(localMedia => localMedia.permissions.videoEnabled)
    );
  }

  private setCameraPreSurgeryStage() {
    this.preSurgeryService.stage = 2;
    this.state = CameraCheckStates.CAMERA_DEFAULT_INFO;
  }

  private cameraEnabled() {
    this.canProgress = true;
  }

  private cameraDisabled() {
    this.state = CameraCheckStates.CAMERA_BLOCKED_ERROR;
  }
}

import { Injectable } from '@angular/core';
import { ApiPatientService } from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { of, zip } from 'rxjs';
import { catchError, defaultIfEmpty, map, tap } from 'rxjs/operators';

export interface PostSurgeryRoute {
  routeName: string;
  actionRequired: boolean;
  responseData: any;
}

@Injectable()
export class PostSurgeryRoutingControllerService {
  constructor(private api: ApiPatientService) {}

  // a stack of routes we will go through one pop at a time
  routeStack: any[] = [];

  /**
   * Using zip (TL;DR: After all observables emit, emit values as an array) we pass
   * in the observable producing functions for each potential route. Then filter out
   * the non-actionable ones and reverse to create the stack. What's left is anything
   * we need the user to act on
   */
  getRoutes() {
    return zip(this.checkForNonCompleteConsultationStatus(), this.thankYou()).pipe(
      map(addRoutes => addRoutes.filter((r: PostSurgeryRoute) => r.actionRequired)),
      tap(actionable => (this.routeStack = [...actionable].reverse()))
    );
  }

  getNextRoute() {
    if (!this.routeStack.length) {
      return this.addRoute('thankyou');
    }

    return this.routeStack.pop() as PostSurgeryRoute;
  }

  private checkForNonCompleteConsultationStatus() {
    return this.api.consultation.getLastConsultationStatus().pipe(
      map(status => {
        switch (status) {
          case 4:
            return this.addRoute('disrupted', status);
          case -1:
            return this.noActionNeeded();
          default:
            return this.noActionNeeded();
        }
      }),
      defaultIfEmpty(this.noActionNeeded()),
      catchError(() => of(this.noActionNeeded()))
    );
  }

  private thankYou() {
    return of(this.addRoute('thankyou'));
  }

  // Helper factory to create a route
  private addRoute(routeName: string, responseData: any = null): PostSurgeryRoute {
    return {
      actionRequired: true,
      responseData,
      routeName,
    };
  }

  // helper factory to create a non actionable route
  private noActionNeeded(): PostSurgeryRoute {
    return {
      actionRequired: false,
      routeName: '',
      responseData: null,
    };
  }
}

import { Component } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { ModalService } from '@pushdr/common/overlay';
import { take } from 'rxjs/operators';

@Component({
  selector: 'pushdr-disrupted-consultation',
  templateUrl: './disrupted-consultation.component.html',
  styleUrls: ['./disrupted-consultation.component.scss'],
})
export class DisruptedConsultationComponent {
  constructor(private modal: ModalService, private envProxy: EnvironmentProxyService) {}

  reconnect() {
    return this.modal
      .confirm(
        'Are you sure you want to reconnect?',
        'Please check your internet connection before you try to reconnect.',
        'Reconnect',
        'Cancel'
      )
      .pipe(take(1))
      .subscribe(confirmed =>
        confirmed ? (location.href = this.envProxy.environment.patient.surgery.url) : null
      );
  }

  exit() {
    location.href = this.envProxy.environment.patient.account.url;
  }
}

import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DisruptedConsultationComponent } from '../disrupted-consultation.component';

@Injectable()
export class PreventBackButtonGuard implements CanDeactivate<DisruptedConsultationComponent> {
  canDeactivate(
    component: DisruptedConsultationComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    return (
      nextState.url.indexOf('/account/appointments/upcoming') > -1 ||
      nextState.url.indexOf('/presurgery/start') > -1
    );
  }
}

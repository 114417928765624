import { Component, OnInit } from '@angular/core';
import { EnvironmentProxyService } from '@pushdr/environment';
import { TokenService } from '@pushdr/common/utils';

@Component({
  selector: 'pushdr-abused-consultation',
  templateUrl: './abused-consultation.component.html',
  styleUrls: ['./abused-consultation.component.scss'],
})
export class AbusedConsultationComponent implements OnInit {
  constructor(private token: TokenService, private envProxy: EnvironmentProxyService) {}

  ngOnInit() {
    this.token.delete();
  }

  close() {
    location.href = this.envProxy.environment.patient.funnel.signin;
  }
}

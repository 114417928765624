import { CurrencyPipe } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AnalyticsService, LOG_CONFIG } from '@pushdr/common/data-access/analytics';
import { CommonOverlayModule } from '@pushdr/common/overlay';
import {
  CommonPipesModule,
  FocusOnElementModule,
  MessageQueue,
  SignalRV3Service,
  StorageService,
  TokenModule,
} from '@pushdr/common/utils';
import { EnvironmentProxyService } from '@pushdr/environment';
import { PatientappCommonComponentsModule } from '@pushdr/patientapp/common/components';
import { PatientappCommonConfigModule } from '@pushdr/patientapp/common/config';
import {
  ApiAccountPatientService,
  ApiConsultationPatientService,
} from '@pushdr/patientapp/common/data-access/patient-legacy-api';
import { AccountProfileModule, PatientAnalyticsService } from '@pushdr/patientapp/common/utils';
import { SimpleModalModule } from 'ngx-simple-modal';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { PostsurgeryModule } from './postsurgery/postsurgery.module';
import { SurgeryRoutingModule } from './surgery-routing.module';
import { AppConfigService } from './app.config.service';
import { SystemNotSupportedRoutingModule } from './system-not-supported/system-not-supported-routing.module';
import { FeedbackReminderModule } from '@pushdr/feedback/reminder';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function loadConfig(appService: AppConfigService) {
  return () => appService.getAppConfig();
}

export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonPipesModule,
    PatientappCommonConfigModule,
    PatientappCommonComponentsModule,
    AccountProfileModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    HttpClientJsonpModule,
    SystemNotSupportedRoutingModule,
    PostsurgeryModule,
    SurgeryRoutingModule,
    SimpleModalModule.forRoot(
      { container: document.body },
      {
        closeOnEscape: false,
        closeOnClickOutside: false,
        wrapperDefaultClasses: 'c-modal',
        bodyClass: 'js-modal-open',
        wrapperClass: 'c-modal--is-visible',
        animationDuration: 300,
        autoFocus: true,
      }
    ),
    CommonOverlayModule,
    TokenModule.forRoot({ tokenName: 'patient_token' }),
    FocusOnElementModule,
    LottieModule.forRoot({ player: playerFactory }),
    FeedbackReminderModule,
  ],
  providers: [
    {
      provide: EnvironmentProxyService,
      useValue: new EnvironmentProxyService().setup(environment),
    },
    {
      provide: AnalyticsService,
      useClass: PatientAnalyticsService,
    },
    {
      provide: MessageQueue,
      useClass: SignalRV3Service,
    },
    {
      provide: LOG_CONFIG,
      useFactory: (
        consultApi: ApiConsultationPatientService,
        accountApi: ApiAccountPatientService,
        storage: StorageService
      ) => {
        return {
          applicationName: 'Patient Surgery',
          get: storage.getSessionStorage,
          set: storage.setSessionStorage,
          delete: storage.deleteSessionStorage,
          emitStored: logs => consultApi.addPatientLogRangeForConsultation(logs),
          emit: log => accountApi.addPatientLog(log),
        };
      },
      deps: [ApiConsultationPatientService, ApiAccountPatientService, StorageService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [AppConfigService],
      multi: true,
    },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

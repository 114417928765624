<section *ngIf="thankYouMessages; else loading" class="c-form-part" trackView="thank you">
  <h1 class="c-form-part__title" pdFocus="true">Consultation Complete</h1>
  <p class="centraliseParagraph">
    We hope you got the help you need. Your feedback is really important to us. Please fill out this
    short survey and let us know how we did.
  </p>

  <a class="c-btn c-btn--primary c-form-part__btn" [href]="feedbackUrl$ | async">
    Leave feedback
  </a>

  <button
    class="w-full h-14 px-6 bg-transparent hover:bg-indigo-500 text-indigo-500 hover:text-white border border-indigo-500 hover:border-transparent rounded"
    type="submit"
    trackClick="continue"
    (click)="continue()"
    aria-label="Click to continue">
    Close
  </button>
</section>

<!-- Loader spinner for the page -->
<ng-template #loading>
  <pushdr-spinner-loader></pushdr-spinner-loader>
</ng-template>

<div class="pre-surgery-feedback" data-cy="feedback" *ngIf="state">
  <div
    class="c-notification"
    [ngClass]="{
      'c-notification__info': state.indexOf('Info') > -1,
      'c-notification__warning': state.indexOf('Warning') > -1,
      'c-notification__error': state.indexOf('Error') > -1
    }"
    [ngSwitch]="state">
    <ng-container *ngSwitchCase="SpeedCheckStates.SPEED_DEFAULT_INFO">
      <h3 class="c-notification__title">Test your connection speed</h3>
      <p class="u-mb0@xs">Connection speed is important to get the best&nbsp;care.</p>
    </ng-container>

    <ng-container *ngSwitchCase="SpeedCheckStates.SPEED_ACTIVE_INFO">
      <h3 class="c-notification__title">Testing your connection speed</h3>
      <p class="u-mb0@xs">
        This shouldn't take more than a few seconds, you don't need to do anything.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="SpeedCheckStates.SPEED_GOOD_INFO">
      <h3 class="c-notification__title">Connection speed good</h3>
      <p class="u-mb0@xs">
        Your connection is above our recommended speeds, please continue to the waiting room.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="SpeedCheckStates.SPEED_OKAY_WARNING">
      <h3 class="c-notification__title">Connection speed ok</h3>
      <p class="u-mb0@xs">
        We recommend a speed of 4 Mbps for a smoother experience. However, your connection should be
        fine for this consultation.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="SpeedCheckStates.SPEED_SLOW_ERROR">
      <h3 class="c-notification__title">Your connection is too weak</h3>
      <p class="u-mb0@xs">
        We recommend you check your settings and read this
        <a
          class="cursor-pointer"
          [pushdrTroubleshooting]="TroubleshootingPage.Connection"
          trackClick="help"
          >advice</a
        >.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="SpeedCheckStates.SPEED_ISSUE_ERROR">
      <h4 class="c-notification__title">There was an issue</h4>
      <p class="u-mb0@xs">
        We recommend you check your settings and read this
        <a
          class="cursor-pointer"
          [pushdrTroubleshooting]="TroubleshootingPage.Connection"
          trackClick="help"
          >advice</a
        >.
      </p>
    </ng-container>

    <ng-container *ngSwitchCase="SpeedCheckStates.SPEED_DEVICE_ERROR">
      <h4 class="c-notification__title">There was an issue</h4>
      <p class="u-mb0@xs">
        There was an issue connecting to your Camera or Microphone. To perform an accurate test we
        need access to these.
      </p>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <h4 class="c-notification__title">There was an issue</h4>
      <p class="u-mb0@xs">Please go back and try again.</p>
    </ng-container>
  </div>
</div>
<div class="test-container u-m-auto">
  <div class="animation-container">
    <ng-lottie
      *ngIf="!isSpeedTestPassed"
      [options]="lottieConfigSpeedTest"
      style="width: 100%"></ng-lottie>
    <ng-lottie
      *ngIf="isSpeedTestPassed && !isSpeedTestActive"
      [options]="lottieConfigSuccess"
      style="width: 100%">
    </ng-lottie>
  </div>
</div>
<div class="pre-surgery-progression" *ngIf="state" [ngSwitch]="true">
  <div>
    <ng-container
      *ngSwitchCase="
        state === SpeedCheckStates.SPEED_DEFAULT_INFO ||
        state === SpeedCheckStates.SPEED_ACTIVE_INFO
      ">
      <button [disabled]="true" class="c-btn c-btn--primary c-btn--small lg:mb-0">
        Starting your speed test
      </button>
    </ng-container>

    <ng-container
      *ngSwitchCase="
        state === SpeedCheckStates.SPEED_SLOW_ERROR || state === SpeedCheckStates.SPEED_ISSUE_ERROR
      ">
      <p>Test the connection again</p>
      <button
        class="c-btn c-btn--primary c-btn--small lg:mb-0"
        (click)="startSpeedTest()"
        data-cy="next"
        trackClick="yes">
        Retry test
      </button>
    </ng-container>

    <ng-container
      *ngSwitchCase="
        state === SpeedCheckStates.SPEED_GOOD_INFO || state === SpeedCheckStates.SPEED_OKAY_WARNING
      ">
      <button
        class="c-btn c-btn--primary c-btn--small lg:mb-0"
        (click)="gotoWaitingRoom()"
        data-cy="next"
        trackClick="yes">
        Go to waiting room
      </button>
    </ng-container>
  </div>
</div>

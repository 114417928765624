<div class="container">
  <div class="row contentContainer">
    <div class="col-md-12 text-center">
      <img
        style="margin: 25px auto; margin-top: 2px"
        alt="logo"
        class="img-responsive"
        src="assets/images/logo-only.png" />
      <h2>Your session has been cancelled by the doctor</h2>
      <p class="centraliseParagraph">
        <strong
          >Your account has been suspended pending review as the doctor was unhappy with the
          circumstance. <br />
          Please contact the customer experience team for more information.</strong
        >
      </p>
      <button (click)="close()" class="btn btn-primary btnContinue">Close</button>
    </div>
  </div>
</div>

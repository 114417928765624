import { Directive, HostListener, Input } from '@angular/core';
import { TroubleshootingPage, TroubleshootingService } from './troubleshooting.service';

@Directive({
  selector: '[pushdrTroubleshooting]',
})
export class TroubleshootingDirective {
  @Input('pushdrTroubleshooting')
  page!: TroubleshootingPage;

  constructor(private troubleshooting: TroubleshootingService) {}

  @HostListener('click') onClick() {
    this.troubleshooting.showTroubleshootingPage(this.page);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PostsurgeryComponent } from './postsurgery.component';
import { DisruptedConsultationComponent } from './disrupted-consultation/disrupted-consultation.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { AbusedConsultationComponent } from './abused-consultation/abused-consultation.component';
import { PreventBackButtonGuard } from './disrupted-consultation/guards/prevent-back-button.guard';

const routes: Routes = [
  {
    path: 'postsurgery',
    component: PostsurgeryComponent,
    children: [
      {
        path: 'disrupted',
        component: DisruptedConsultationComponent,
        canDeactivate: [PreventBackButtonGuard],
      },
      {
        path: 'doctordisrupted',
        component: AbusedConsultationComponent,
      },
      {
        path: 'thankyou',
        component: ThankYouComponent,
      },
    ],
  },
  {
    path: 'postSurgery',
    redirectTo: '/postsurgery',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class PostsurgeryRoutingModule {}

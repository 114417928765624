import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { preSurgeryRoutes, PatientappPreSurgeryModule } from '@pushdr/patientapp/pre-surgery';
import { PresurgeryWrapperComponent } from './pre-surgery-wrapper/pre-surgery-wrapper.component';

const routes: Routes = [
  {
    path: 'presurgery',
    component: PresurgeryWrapperComponent,
    children: preSurgeryRoutes,
  },
  {
    path: 'surgery',
    loadChildren: () =>
      import('@pushdr/patientapp/peri-surgery').then(m => m.PatientappPeriSurgeryModule),
  },
];

@NgModule({
  declarations: [PresurgeryWrapperComponent],
  imports: [
    PatientappPreSurgeryModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule],
})
export class SurgeryRoutingModule {}
